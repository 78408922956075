<template>
  <div>
    <div class="button is-small pause is-info" @click="pauseStart()">Pause</div>
  </div>
</template>

<script>
export default {
  props: {
    currentTask: Object
  },
  computed: {
    operatingUser () {
      return this.$store.state.operatingUser
    },
    taskId () {
      let taskId = null
      if (this.currentTask) {
        taskId = this.currentTask.id
      }
      return taskId
    }
  },
  methods: {
    pauseStart () {
      this.timerRunning = false
      this.trackTime(this.taskId, 'pause_start')
      this.locked = true
    },
  }
}
</script>

<style lang="sass" scoped>
.button.pause
  height: 35px
</style>
