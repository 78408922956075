<template>
  <div>
    <div class="button is-small lock" @click="locked = true">Mitarbeiter wechseln</div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="sass" scoped>
.button.lock
  height: 35px
</style>
