<template>
  <div class="login">
    <div class="columns is-centered">
      <div class="column is-12-mobile is-5-desktop is-4-widescreen" align="center">
        <div :class="['box', {'shake' : animated}]">
          <img src="@/assets/images/logo.png" class="logo" />
          <div class="field">
            <input type="text" class="input" placeholder="E-Mail" v-model="email" />
          </div>
          <div class="field">
            <input type="password" class="input" placeholder="Passwort" v-model="password" />
          </div>
          <div class="field">
            <div :class="['button is-primary', { 'is-loading' : loading }]" @click="auth()">Anmelden</div>
          </div>
          <p class="has-text-danger" v-if="errors">Benutzername oder Passwort falsch</p>
        </div>
      </div>
    </div>
    <div align="center">Terminal Version 2.05</div>
  </div>
</template>

<script>
import client from '@/client'
export default {
  data () {
    return {
      email: null,
      password: null,
      loading: false,
      errors: null,
      animated: false,
    }
  },
  mounted () {
    const rememberedEmail = localStorage.getItem('rememberedEmail')
    if (rememberedEmail) {
      this.email = rememberedEmail
    }
  },
  methods: {
    async auth () {
      this.loading = true
      this.errors = undefined
      const data = { email: this.email, password: this.password }
      try {
        const res = await client.post('/auth', data)
        if (res) {
          const jwt = res.data.jwt_token
          localStorage.setItem('auth._token.jwt', jwt)
          localStorage.setItem('rememberedEmail', this.email)

          // set user
          let user = res.data.user
          if (user) {
            this.$store.commit('setCurrentUserAndOrganization', user)
            this.$store.commit('setAuthorized', true)
          }
        }
      } catch (error) {
        if (error.response) {
          this.errors = error.response.data
          this.loading = false

          // animate shake
          const self = this
          self.animated = true
          setTimeout(() => {
            self.animated = false
          }, 1000)
        }
      }
    },
  }
}
</script>

<style lang="css" scoped>
.shake {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
<style lang="sass" scoped>
.box
  .logo
    width: 80%
    margin-bottom: 20px
</style>
