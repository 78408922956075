<template>
  <div>
    <div class="button is-small pause" @click="warningModalOpen = true">Gehen</div>

    <WarningModal
      v-if="warningModalOpen"
      @close="warningModalOpen = false"
      @confirm="logout()"
      :loading="loading"
    >
      Möchten Sie sich wirklich abmelden?
    </WarningModal>
  </div>
</template>

<script>
import WarningModal from '@/components/Modal'
export default {
  components: { WarningModal },
  props: {
    currentTask: Object
  },
  data () {
    return {
      warningModalOpen: false,
      loading: false
    }
  },
  computed: {
    taskId () {
      let taskId = null
      if (this.currentTask) {
        taskId = this.currentTask.id
      }
      return taskId
    }
  },
  methods: {
    async logout () {
      this.loading = true
      await this.trackTime(this.taskId, 'stop')
      this.loading = false
      this.$store.commit('setTimerRunning', false)
      this.$store.commit('setOperatingOrder', null)
      this.$store.commit('setOperatingUser', null)
      this.warningModalOpen = false
    }
  }
}
</script>

<style lang="sass" scoped>
.button.pause
  height: 35px
</style>
